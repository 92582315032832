<template>
  <v-container :class="darkmode ? 'grey darken-4' : 'grey lighten-5'" flat>
    <!-- Start Profile -->
    <v-row class="px-3">
      <v-col cols="12">
        <v-alert
          v-if="isApplicant"
          icon="mdi-information-outline"
          prominent
          text
          type="info"
          >{{ applicantMessage.message }}
        </v-alert>
      </v-col>
      <!-- Start Student Info -->
      <v-col v-if="!isStudent" cols="12" sm="4">
        <v-card class="pa-5 elevation-0">
          <div class="align-center d-flex flex-column">
            <div class="font-weight-bold headline">
              {{ userProfile.fullname }}
            </div>
            <v-avatar size="200" class="my-5">
              <v-img :src="getPhoto()" :alt="userProfile.fullname" />
            </v-avatar>
          </div>
          <v-row class="justify-space-between d-flex">
            <v-col cols="12" sm="4">
              <div class="font-weight-bold">
                {{ $t("app.nip") }}
              </div>
              <div>
                {{ userProfile.nip || "-" }}
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" sm="8" class="d-flex flex-column">
              <div class="font-weight-bold">
                Email
              </div>
              <div>
                {{ userProfile.email || "-" }}
              </div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="font-weight-bold">{{ $t("dashboard.address") }}</div>
              <div>{{ userProfile.address || "-" }}</div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="font-weight-bold">{{ $t("dashboard.phone") }}</div>
              <div>{{ userProfile.phone || "-" }}</div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- End Student Info -->
      <v-col cols="12" :sm="!isStudent ? '8' : '12'">
        <v-row>
          <v-col cols="12" sm="5">
            <v-card class="elevation-0 align-center pa-3 d-flex flex-row">
              <div class="d-flex flex-column">
                <span class="font-weight-bold">{{
                  $t("dashboard.today")
                }}</span>
                <span class="body-2">
                  {{ currentDate }}
                </span>
                <span class="font-weight-bold mt-5">
                  {{ $t("dashboard.hour_activity") }}
                </span>
                <span class="body-2">
                  {{ schedule.hour_activity }}
                </span>
              </div>
              <v-spacer />
              <v-icon color="primary" large>
                mdi-clock
              </v-icon>
            </v-card>
            <v-card class="pa-3 align-center elevation-0 mt-5 d-flex flex-row">
              <div class="d-flex flex-column">
                <span class="font-weight-bold">{{
                  $t("dashboard.activity")
                }}</span>
                <span class="body-2">
                  {{ schedule.activity }}
                </span>
                <span class="font-weight-bold mt-5">
                  {{ $t("dashboard.extracuricular_today") }}
                </span>

                <span v-if="extracuricular.length === 0" class="body-2">
                  -
                </span>
                <ul v-else>
                  <li
                    v-for="(item, index) in extracuricular"
                    :key="index"
                    class="body-2"
                  >
                    {{ item.name }} (<span class="caption"
                      >{{ item.start_time }} - {{ item.end_time }}</span
                    >)
                  </li>
                </ul>
              </div>
              <v-spacer />
              <v-icon color="primary" large>
                mdi-clipboard-text
              </v-icon>
            </v-card>
          </v-col>
          <v-col v-if="!isApplicant" cols="12" sm="7">
            <v-card class="pa-3 elevation-0">
              <div class="d-flex flex-column mb-3">
                <span class="font-weight-bold">
                  {{ $t("dashboard.attendance_recap") }}
                </span>
                <span>{{ currentMonth }}</span>
              </div>
              <v-row>
                <v-col
                  v-for="(item, index) in absence"
                  :key="index"
                  class="12"
                  sm="6"
                >
                  <div
                    :style="
                      index === 2 || index === 3 ? 'margin-top: 10px' : ''
                    "
                    :class="darkmode || 'border-absence'"
                    class="rounded-lg"
                  >
                    <v-card
                      :class="darkmode ? 'grey darken-4' : 'grey lighten-5'"
                      class="pa-3 align-center elevation-0 d-flex flex-row"
                    >
                      <div class="d-flex flex-column">
                        <span class="font-weight-bold">
                          {{ item.name }}
                        </span>
                        <div
                          :class="item.color"
                          class=" my-2 rounded-circle d-flex justify-center align-center"
                          style="width: 35px; height: 35px"
                        >
                          <v-icon color="white">
                            {{ item.icon }}
                          </v-icon>
                        </div>
                      </div>
                      <v-spacer />
                      <div class="d-flex flex-column align-center">
                        <span class="font-weight-bold display-1 grey--text">{{
                          item.daysTotal
                        }}</span>
                        <span class="font-weight-bold title grey--text">{{
                          $t("dashboard.day")
                        }}</span>
                      </div>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- End Profile -->
    <StudentProfiling
      v-if="isStudent"
      :userProfile="userProfile"
      :homeroom="homeroomTeacher"
    />

    <div class="ma-5">
      <h2 class="headline mb-4 d-flex align-center">
        <img
          height="36"
          width="36"
          src="@/assets/svg/icon_announcement.svg"
          class="mr-2"
        />
        <span class="grey--text">
          {{ $t("dashboard.announcement") }}
        </span>
      </h2>
      <Announcement dark-color="grey darken-4" />
    </div>
    <div class="ma-5">
      <h2 class="headline mb-4 d-flex align-center">
        <img
          height="36"
          width="36"
          src="@/assets/svg/icon_news.svg"
          class="mr-2"
        />
        <span class="grey--text">
          {{ $t("dashboard.news") }}
        </span>
      </h2>
      <News dark-color="grey darken-4" />
    </div>
  </v-container>
</template>

<script>
import { isExternal } from "@/utils/validate";
import {
  homeroomTeacher,
  schedule,
  extracuricular,
  absence,
  applicantMessage
} from "@/api/admin/profile";
import moment from "moment/moment";

export default {
  metaInfo: {
    title: "Profile",
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Announcement: () => import("@/views/homepage/components/Announcement"),
    News: () => import("@/views/homepage/components/News"),
    StudentProfiling: () =>
      import("@/views/admin/master/student/ProfilingStudent")
  },
  created() {
    moment.locale(this.$store.getters.g_language);
    this.currentDate = moment().format("dddd, DD MMMM YYYY");
    this.currentMonth = moment().format("MMMM YYYY");
    this.getHomeroomTeacher();
    this.getSchedule();
    this.getExtracuricular();
    this.getAbsence();
    this.getApplicantMessage();
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    },
    isStudent() {
      return this.$store.getters.g_role_type === "STUDENT";
    },
    isApplicant() {
      return this.$store.getters.g_roles
        ? this.$store.getters.g_roles.some(role => role === 1 || role === 2)
        : null;
    }
  },
  data() {
    return {
      currentDate: moment().format("dddd, DD MMMM YYYY"),
      currentMonth: moment().format("MMMM YYYY"),
      userProfile: this.$store.getters.g_user,
      boyPhoto: require("@/assets/svg/boy.svg"),
      girlPhoto: require("@/assets/svg/girl.svg"),
      homeroomTeacher: {
        name: "-",
        address: "-",
        phone: "-"
      },
      extracuricular: [], // [{name: "", start_time: "", end_time: ""}]
      schedule: {
        hour_activity: "-",
        activity: "-"
      },
      applicantMessage: { message: "" },
      absence: [
        {
          name: this.$i18n.t("dashboard.present"),
          icon: "mdi-check-circle-outline",
          daysTotal: 0,
          color: "gradient-success"
        },
        {
          name: this.$i18n.t("dashboard.not_present"),
          icon: "mdi-close-circle-outline",
          daysTotal: 0,
          color: "gradient-error"
        },
        {
          name: this.$i18n.t("dashboard.leave"),
          icon: "mdi-pencil-box-outline",
          daysTotal: 0,
          color: "gradient-primary"
        },
        {
          name: this.$i18n.t("dashboard.sick"),
          icon: "mdi-thermometer-lines",
          daysTotal: 0,
          color: "gradient-warning"
        }
      ]
    };
  },
  methods: {
    async getHomeroomTeacher() {
      try {
        const response = await homeroomTeacher();
        if (response.data.code && response.data.data) {
          this.homeroomTeacher = response.data.data;
        }
      } catch (error) {
        console.log("getHomeroomTeacher()", error);
      }
    },
    async getAbsence() {
      try {
        const alphaResponse = await absence({ type: "alpha" });
        const absenceResponse = await absence({ type: "absence" });
        const permissionResponse = await absence({ type: "permission" });
        const sickResponse = await absence({ type: "sick" });
        this.absence[0].daysTotal = absenceResponse.data.data.count;
        this.absence[1].daysTotal = alphaResponse.data.data.count;
        this.absence[2].daysTotal = permissionResponse.data.data.count;
        this.absence[3].daysTotal = sickResponse.data.data.count;
      } catch (error) {
        console.log("getAbsence()", error);
      }
    },
    async getExtracuricular() {
      try {
        const response = await extracuricular();
        if (response.data.code && response.data.data.length) {
          this.extracuricular = response.data.data;
        }
      } catch (error) {
        console.log("getExtracuricular()", error);
      }
    },
    async getApplicantMessage() {
      if (this.isApplicant) {
        try {
          const response = await applicantMessage();
          if (response.data.code) {
            this.applicantMessage = response.data.data;
          }
        } catch (error) {
          console.log("getApplicantMessage()", error);
        }
      }
    },
    async getSchedule() {
      try {
        const response = await schedule();
        if (response.data.code) {
          this.schedule = response.data.data;
        }
      } catch (error) {
        console.log("getSchedule()", error);
      }
    },
    getPhoto() {
      let photo = "";
      if (isExternal(this.userProfile.photo)) {
        photo = this.userProfile.photo;
      } else {
        switch (this.$store.getters.g_user.gender) {
          case "L":
            photo = this.boyPhoto;
            break;
          case "P":
            photo = this.girlPhoto;
            break;
          default:
            photo = this.boyPhoto;
            break;
        }
      }
      return photo;
    }
  }
};
</script>
<style lang="scss" scoped>
.dark-mode {
  background: #121212;
  border: 10px solid #424242;
}
.divider {
  border-right: 1px solid #e2e2e2;
}
.border-absence {
  border: solid #f1f1f1;
}
</style>
