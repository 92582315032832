import request from "@/utils/request";

export function homeroomTeacher() {
  return request({
    method: "post",
    url: "profile/homeroom_teacher"
  });
}

export function schedule() {
  return request({
    method: "post",
    url: "profile/schedule"
  });
}

export function extracuricular() {
  return request({
    method: "post",
    url: "profile/extra_curricularr"
  });
}

export function absence(data) {
  return request({
    method: "post",
    url: "profile/absence",
    data: data
  });
}

export function applicantMessage() {
  return request({
    method: "post",
    url: "profile/applicant_message"
  });
}
